<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel   height="360px" style="width: 100%;" :autoplay="false" arrow="always">
      <el-carousel-item v-for="(item, index) in introductionList" :key="index">
        <div class="bannerItemDiv" style="height: 100%;" :style="{'background':`url(${item.barImageUrl}` }"></div>
        <!-- <img height="100%" width="100%" :src="item.barImageUrl" alt=""> -->
      </el-carousel-item>
    </el-carousel>
    <!-- 课程选择 -->
    <div class="kcxz">
      <div>
        <!-- <div class="xxkc">
          <img src="@/assets/imgs/png.png" alt="">
          <span>线下课程</span>
        </div> -->
        <div class="kecSele resouceAndconsultFz">
          <div v-for="(item, index) in kecSele " :key="index" :class="{ 'dbitem': lineType === item.lineType }"
            @click="setActive(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div style="margin-top:30px">
        <!-- key 应为id -->
        <div class="resourceItem" style="width:20%" v-for="(item, index) in resourcelist" :key="item.courseId"
          :style="{ marginRight: item.isfour ? '0px' : '6%' }" @click="handclick(item)">
          <img class="pic" :src="item.courseImageUrl"></img>
          <div class="onebox" style="height: 20px;">
            <span class="priceone">{{ item.price }}</span>
            <span class="pricetwo"> 原价{{ item.originalPrice || '--' }} </span>
            <span class="favorable">{{ item.courseGreat }}好评</span>
          </div>
          <div class="twobox">
            <img class="time" src="@/assets/imgs/time.png"></img>
            <span class="timeLine">{{ item.startTime }} </span>
            <span class="buypeople"> {{ item.courseEnrollment }}已购</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { bannerList, getCourseList } from "@/api/resourceAndconsult"
      import {
  gkzyCommonParameters
} from "@/api/commonparams"
export default {
  name: "course",
  data() {
    return {
      introductionList: [],
      resourcelist: [],
      value: "课程界面",
      lineType: 1,
      kecSele: [

        { name: '热门课程', lineType: 1 },
         { name: '线下课程', lineType: 2 },
      ],
      kcitem: [
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '免费参与',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '7777777'

        },
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '498',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '201077'

        },
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '899',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '220077'

        },
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '699',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '230077'

        },
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '875',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '250077'

        },
        {
          img: "@/assets/imgs/resourcePic.png",
          jiage1: '239',
          jiage2: '299',
          haoping: "97%",
          time: '2023-01-25',
          goumai: '270077'

        },
      ]
    };
  },
  methods: {
    setActive(item) {
      this.lineType = item.lineType
      this.getResourceList()
    },
    handclick(item) {
         gkzyCommonParameters({
            parameterType: '课程进入'
          }).then(res => {
              let courseJoin = res.rows[0].parameterValue
              if(courseJoin == 2){  //禁止进入
                    return this.$message.warning('内容正在上传中，敬请期待')
              }
              this.$router.push({ path: "/courseitem", query: { courseId: item.courseId, type: item.type } })

            })

    },
    // 获取课程轮播
    getREsourceBanner() {
      bannerList({imageType:1}).then(res => {
        this.introductionList = res.data.map(item => {
          return { ...item, barImageUrl: process.env.VUE_APP_BASE_API + item.barImageUrl }
        })
      })
    },
    // 获取课程list
    getResourceList() {
      const arr = [3, 7,11,15,19,23,27]
      getCourseList({ type: this.lineType }).then(res => {

        this.resourcelist = res.data.map((item, index) => {
          return {
            ...item,
            courseImageUrl: process.env.VUE_APP_BASE_API + item.courseImageUrl,
            startTime: item.startTime.substr(0, 10),
            isfour: arr.includes(index) ? true : false
          }
        })
      })
    },
  },
  mounted() {
    // 获取课程轮播
    this.getREsourceBanner()
    // 获取课程list
    this.getResourceList()
  }
}
</script>

<style lang="scss" scoped>
.kcxz {
  // width: 74%;
  width: 1125px;
  min-height: 700px;
  margin: auto;
  // border: 1px solid #000;
}

.xxkc {
  position: relative;
  width: 15%;
  height: 55.5px;
  background-size: 100% 100%;
  line-height: 55.5px;
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  align-content: center;
  padding-left: 15px;
  float: left;
}

.xxkc span {
  position: absolute;
  top: 15%;
  left: 17%;
  display: block;
  width: 100%;
  letter-spacing: 6px;
}

.xxkc img {
  width: 110%;
}

.kecSele {
  width: 18%;
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-top: 2.5%;
  padding-left: 5%;
  cursor: pointer;
}

.dbitem {
  border-bottom: 5px solid #f54243;
  border-bottom-width: 80%;
}











</style>
